<template>
  <div class="flex flex-col">
    <div class="flex mb-5">
      <h1 class="text-xl font-semibold text-gray-900">
        ALL SCANS
      </h1>
    </div>

    <div class="flex flex-col gap-1 justify-center w-full h-full mb-5">
      <span class="text-sm font-semibold">
        In Progress
      </span>

      <span class="text-xs">
        No scans in progress.
      </span>
    </div>

    <div class="flex flex-col gap-1 justify-center w-full h-full mb-7">
      <span class="text-sm font-semibold">
        Scheduled
      </span>

      <span class="text-xs">
        All repeating or one-off scans manually scheduled for a later date.
      </span>
    </div>

    <Card class="mb-11">
      <template #content>
        <div class="flex flex-row justify-between">
          <div class="flex flex-row items-center gap-2">
            <div>
              <ChevronRightIcon
                class="h-8 w-8"
                aria-hidden="true"
              />
            </div>

            <div class="flex flex-col">
              <div>
                Monthly Scan
              </div>

              <div class="flex text-xs">
                Repeats monthly
              </div>
            </div>
          </div>

          <div class="flex flex-row gap-20 items-center">
            <div class="flex flex-row gap-1.5">
              <div>
                <CalendarIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </div>

              <div class="text-sm">
                14 Apr 2022 03:22
              </div>
            </div>

            <div class="flex flex-row gap-3">
              <div>
                <PencilIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </div>

              <div>
                <TrashIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>

    <div class="flex flex-col gap-1 justify-center w-full h-full">
      <span class="text-sm font-semibold">
        History
      </span>

      <span class="text-xs">
        All repeating or one-off scans manually scheduled for a later date.
      </span>
    </div>

    <Card>
      <template #content>
        <div class="flex flex-row justify-between">
          <div class="flex flex-row items-center gap-2">
            <div>
              <ChevronRightIcon
                class="h-8 w-8"
                aria-hidden="true"
              />
            </div>

            <div class="flex flex-col">
              <div>
                One-off scan
              </div>

              <div class="flex text-xs">
                14 Mar 2022 01:23
              </div>
            </div>
          </div>

          <div class="flex flex-row items-center">
            <div class="flex flex-row gap-1.5">
              <div>
                <CheckCircleIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </div>

              <div class="text-sm">
                Completed
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

import { ChevronRightIcon } from '@heroicons/vue/solid'
import { CalendarIcon, PencilIcon, TrashIcon, CheckCircleIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Card,
    ChevronRightIcon,
    CalendarIcon,
    PencilIcon,
    TrashIcon,
    CheckCircleIcon,
  },

  data: () => ({
  }),
}
</script>
