<template>
  <div 
    class="rounded-lg shadow-lg flex flex-col gap-1 p-4 pb-3"
    :class="background"
  >
    <div
      v-if="$slots['icon'] && $slots['title']"
      class="text-sm font-semibold pb-2 flex gap-2 items-center"
    >
      <slot name="icon" />

      <slot name="title" />
    </div>

    <div
      v-else-if="$slots['title']"
      class="text-md font-semibold pb-2"
    >
      <slot name="title" />
    </div>

    <div
      v-if="$slots['sub-title']"
      class="text-sm font-semibold pb-2"
    >
      <slot name="sub-title" />
    </div>

    <div class="pl-0.5">
      <slot name="content" />
    </div>

    <div
      v-if="$slots['footer']"
      class="flex items-end pt-6 w-full"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '',
    },
  },

  computed: {
    background() {
      switch(this.bgColor) {
        case 'red':
          return 'bg-red-300 text-gray-100';
        case 'blue':
          return 'bg-blue-300 text-gray-100';
        case 'dark-blue':
          return 'bg-blue-700 text-gray-50';
        case 'yellow':
          return 'bg-yellow-300 text-gray-800';
        case 'pink':
          return 'bg-pink-300 text-gray-800';
        case 'indigo':
          return 'bg-indigo-300 text-gray-800';
        case 'dark-indigo':
          return 'bg-indigo-700 text-gray-100';

        default:
          return 'bg-white text-black';
      }
    },
  },
};
</script>